import React, { useRef } from "react"
import { graphql } from "gatsby"
import { CarouselFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./carousel.module.scss"
import { useSlugs } from "../../../../context/slugs-context"
import { useIsMobile } from "../../../../hooks"
import ScrollArrow from "../../../tools/scroll-arrow/scroll-arrow"

type RenderProps = {
  links: CarouselFieldsFragment[]
}

const Carousel: React.FC<RenderProps> = ({ links }) => {
  const isMobile = useIsMobile()
  const { buildInternalLink } = useSlugs()
  const cards = useRef(null)

  const scrollToCard = (scrollDirection: number) => {
    const itemWidth = cards.current.children[0].clientWidth + 20
    cards.current.scrollBy({
      left: itemWidth * scrollDirection,
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <>
      <ul className={styles.cardsContainer} ref={cards}>
        {links
          .filter(link => link.image)
          .map((link, index) => {
            const textColor =
              link.__typename !== "DatoCmsPublication"
                ? link.miniatureCardMapModule?.cardTextColor?.hex || "#fff"
                : "#fff"
            const internalLink =
              link.__typename !== "DatoCmsPublication"
                ? buildInternalLink(link)
                : link.isPdf
                  ? link.pdf?.url
                  : link.externalLink

            return (
              <li className={styles.card} key={index}>
                <a href={internalLink}>
                  <img className={styles.image} src={link.image.url} alt={link.image.alt || ""} />
                  <div className={styles.cardContent}>
                    <span className={styles.title} style={{ "--color": textColor } as React.CSSProperties}>
                      {link.title}
                    </span>
                  </div>
                </a>
              </li>
            )
          })}
      </ul>
      {links.length > 1 && !isMobile && (
        <div className={styles.arrowsContainer}>
          <ScrollArrow direction="left" onClick={() => scrollToCard(-1)} />
          <ScrollArrow direction="right" onClick={() => scrollToCard(1)} />
        </div>
      )}
    </>
  )
}

export const fragment = graphql`
  fragment CarouselFields on DatoCmsUnionForDatoCmsHomePageMapModuleCarouselLinks {
    __typename
    ... on DatoCmsPage {
      title
      image {
        alt
        url
      }
      slug
      miniatureCardMapModule {
        cardTextColor {
          hex
        }
      }
    }
    ... on DatoCmsNews {
      title
      image {
        alt
        url
      }
      slug
      miniatureCardMapModule {
        cardTextColor {
          hex
        }
      }
    }
    ... on DatoCmsPressRelease {
      title
      image {
        alt
        url
      }
      slug
      miniatureCardMapModule {
        cardTextColor {
          hex
        }
      }
    }
    ... on DatoCmsPublication {
      title
      image {
        alt
        url
      }
      isPdf
      pdf {
        url
      }
      externalLink
    }
  }
`

export default Carousel
