import React, { useState } from "react"
import { graphql } from "gatsby"
import { AllPressReleasesQuery, DatoCmsSlugsConfiguration } from "../../../../../graphql-types"
import * as styles from "../all-page.module.scss"
import { useFormatLocale, useIsMobile, useSlugValue, useSortArticlesByMonth } from "../../../hooks"
import Layout from "../../../components/layout/layout"
import Breadcrumb from "../../../components/breadcrumb/breadcrumb"
import MediaCards from "../../../components/media-cards/media-cards"
import Pagination from "../../../components/pagination/pagination"
import SEO from "../../../components/seo/seo"
import { HrefLangLinks } from "../../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: AllPressReleasesQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

const AllPressReleasesPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()
  const pressReleases = useSortArticlesByMonth(data.allDatoCmsPressRelease.nodes, pageContext.locale)
  const [filteredPressReleases, setFilteredPressReleases] = useState(pressReleases)
  const currentRoute = `${useSlugValue(
    pageContext.slugs._allNewsroomPageSlugLocales,
    pageContext.locale
  )}/${useSlugValue(pageContext.slugs._allPressReleasesPageSlugLocales, pageContext.locale)}`

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={currentRoute}
      pageTemplate="all press releases"
    >
      <section className={styles.container}>
        {!isMobile && (
          <Breadcrumb
            title={data.datoCmsNewsroom.allPressReleasesPageTitle}
            homeLabel={data.datoCmsGlobalConfiguration.homePageTitle}
            middlePages={[
              {
                id: "newsroom",
                title: data.datoCmsNewsroom.newsroomPageTitle,
                isInternalPage: true,
                internalLink: {
                  __typename: "DatoCmsGeneratedPage",
                  slug: useSlugValue(pageContext.slugs._allNewsroomPageSlugLocales, pageContext.locale),
                },
              },
            ]}
          />
        )}
        <h1 className={styles.title}>{data.datoCmsNewsroom.allPressReleasesPageTitle}</h1>

        <Pagination
          articles={pressReleases}
          filteredArticles={filteredPressReleases}
          onSelect={setFilteredPressReleases}
        />

        {filteredPressReleases?.map(monthlyNews => (
          <MediaCards
            title={monthlyNews.publicationDate}
            pressReleases={monthlyNews.articles}
            multiRow={!isMobile}
            key={monthlyNews.publicationDate}
          />
        ))}
      </section>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsNewsroom.newsroomPageTitle}
      description={data.datoCmsGlobalConfiguration.metaDescription}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <HrefLangLinks
        defaultDomain={window.location.origin}
        defaultLocale={pageContext.defaultLocale}
        slugs={[pageContext.slugs._allNewsroomPageSlugLocales]}
      />
    )}
  </>
)

export const pageQuery = graphql`
  query AllPressReleases($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }
    allDatoCmsPressRelease(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsPressRelease {
          ...PressReleaseMediaFields
        }
      }
    }
    datoCmsNewsroom(locale: $locale) {
      newsroomPageTitle
      allPressReleasesPageTitle
      ...NewsroomLiveFields
    }
  }
`

export default AllPressReleasesPage
