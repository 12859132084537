import { ContactPageFormFieldsFragment } from "../../../../graphql-types"
import { render } from "datocms-structured-text-to-html-string"
import { StructuredText as StructuredTextGraphQlResponse } from "datocms-structured-text-utils/dist/types/types"
import sanitizeHtml from "sanitize-html"
import { decode } from "html-entities"

const cleanHtmlTags = (value: string) => {
  return sanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {},
  })
}

export function contactPageFormToText(
  content: {
    value?: any
    blocks?: Array<string | null> | null
    links?: Array<{ fragmentType?: string | null; id?: string | null } | null> | null
  },
  moduleConfiguration: ContactPageFormFieldsFragment,
  values: { [p: string]: any }
) {
  const renderFormContent = (renderNode, renderText) => {
    let formContent = []
    let acceptTerms = ""

    if ("acceptTerms" in values) {
      const terms = values.acceptTerms ? moduleConfiguration.acceptedTermsEmail : moduleConfiguration.refusedTermsEmail
      acceptTerms = renderNode("p", {}, renderText(decode(terms)))
    }

    moduleConfiguration.formFields.forEach(field => {
      formContent.push({ label: field.name, value: values[field.name] })
    })

    const formContentFormat = formContent.map(({ label, value }) => {
      return renderNode("tr", {}, [
        renderNode("th", {}, renderText(decode(cleanHtmlTags(label)))),
        renderNode("td", {}, renderText(decode(cleanHtmlTags(value)))),
      ])
    })

    if (acceptTerms.length > 0) {
      formContentFormat.unshift(renderNode("p", {}, renderText(decode(cleanHtmlTags(acceptTerms)))))
    }
    return formContentFormat
  }

  const options = {
    renderBlock(block) {
      if (block.fragmentType === "Email content") {
        return block.renderNode("table", {}, renderFormContent(block.adapter.renderNode, block.adapter.renderText))
      } else {
        console.log("Unhandled fragment: " + block.fragmentType)
      }
    },
    renderInlineRecord({ record: block, adapter: { renderNode, renderText } }) {
      if (block.fragmentType === "Form content") {
        return renderNode("table", {}, renderFormContent(renderNode, renderText))
      } else {
        console.log("Unhandled fragment: " + block.fragmentType)
      }
    },
  }
  return render(content as unknown as StructuredTextGraphQlResponse, options)
}
