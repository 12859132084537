import React, { useRef } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { BiographicCardHorizontalFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./biographic-card-horizontal.module.scss"
import LinkedinGray from "../../../../../../static/images/linkedin_gray.svg"
import TwitterGray from "../../../../../../static/images/twitter_gray.svg"
import CarouselRightArrow from "../../../../../../static/vectors/carousel_right_arrow.svg"
import { DisplayImage } from "../../../../../components/display-image/display-image"

type RenderProps = {
  data: BiographicCardHorizontalFieldsFragment
}

const BiographicCardHorizontal: React.FC<RenderProps> = ({ data }) => {
  const refContainer = useRef(null)

  /**
   * Description: Slide each card to the right
   */
  const slideRight = () => {
    const itemWidth = refContainer.current.children[0].clientWidth
    // the 32 is the same pixels as the right margin of the container
    refContainer.current.scrollBy({
      left: itemWidth + 32,
      top: 0,
      behavior: "smooth",
    })
  }

  const displayIcon = (link: string, icon: string, style: string) => {
    return (
      <a href={link} className={style}>
        <ReactSVG src={icon} aria-hidden={true} />
      </a>
    )
  }

  return (
    <div className={styles.biographicBlock}>
      <div className={styles.emptyLeftPart} />
      <div className={styles.cardsContainer} ref={refContainer}>
        {data.members?.map(member => (
          <div key={member.id} className={styles.container}>
            <div className={styles.content}>
              <div className={styles.photoContainer}>
                <DisplayImage image={member.photo} svgStyle={styles.photo} imgStyle={styles.photo} />
              </div>
              <div className={styles.bioContainer}>
                <div className={styles.name}>{member.name}</div>
                <div className={styles.job}>{member.function}</div>
                <div className={styles.linksContainer}>
                  {member.linkedinProfile && displayIcon(member.linkedinProfile, LinkedinGray, styles.linkLinkedin)}
                  {member.twitterProfile && displayIcon(member.twitterProfile, TwitterGray, styles.linkTwitter)}
                </div>
              </div>
            </div>
          </div>
        ))}
        <button className={styles.sliderButton} onClick={slideRight} aria-label="Élément suivant">
          <ReactSVG src={CarouselRightArrow} />
        </button>
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment BiographicCardHorizontalFields on DatoCmsBiographicCardHorizontal {
    members {
      id
      name
      function
      twitterProfile
      linkedinProfile
      photo {
        alt
        format
        title
        url
      }
      dedicatedPage
    }
  }
`

export default BiographicCardHorizontal
