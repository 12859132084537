import React, { useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import TagManager from "react-gtm-module"
import { FieldValues, useForm } from "react-hook-form"
import { graphql } from "gatsby"
import { ContactPageQuery, DatoCmsSlugsConfiguration } from "../../../../graphql-types"
import * as styles from "./contact-page.module.scss"
import { useFormatLocale, useSlugValue } from "../../hooks"
import { sendEmail } from "../../../api/notifications"
import { contactPageFormToText } from "./contact-page-form-to-text"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import SimpleText from "../../components/rich-dato-cms-content/simple-text/simple-text"
import Layout from "../../components/layout/layout"
import FormInputs from "./form-inputs/form-inputs"
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich_dato_cms_content"
import SuccessPage from "./success-page/success-page"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: ContactPageQuery
  location: Location
  pageContext: {
    id: string
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

const ContactPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const [recaptchaValidated, setRecaptchaValidated] = useState(false)
  const [showRecaptchaError, setShowRecaptchaError] = useState(false)
  const [onSubmitError, setOnSubmitError] = useState(false)
  const [onSubmitSuccess, setOnSubmitSuccess] = useState(false)
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    addEventListener("click", () => {
      if (focus) setFocus(false)
    })
    addEventListener("keydown", e => {
      if (!focus && e.keyCode === 9) setFocus(true)
    })
  }, [focus])

  const displayRecaptcha = () => {
    const siteKey = data.datoCmsFormConfiguration.recaptchaSiteKey
    const validateHuman = (token: string) => {
      if (token) {
        setRecaptchaValidated(true)
        setShowRecaptchaError(false)
      }
    }

    return (
      <>
        <ReCAPTCHA className={styles.captcha} sitekey={siteKey} onChange={validateHuman} />
        {showRecaptchaError && (
          <div className={styles.errorCaptcha}>{data.datoCmsFormConfiguration.recaptchaError}</div>
        )}
      </>
    )
  }

  const onSubmit = async (formData: FieldValues) => {
    if (recaptchaValidated) {
      try {
        const userEmailContent = contactPageFormToText(
          data.datoCmsContactPageForm.userEmailContent,
          data.datoCmsContactPageForm,
          formData
        )
        const subsidiaryEmailContent = contactPageFormToText(
          data.datoCmsContactPageForm.subsidiaryEmailContent,
          data.datoCmsContactPageForm,
          formData
        )

        const userEmailPromise = sendEmail(
          data.datoCmsContactPageForm.fromEmail,
          formData.email,
          data.datoCmsContactPageForm.userEmailSubject,
          userEmailContent
        )
        const subsidiaryEmailPromise = sendEmail(
          data.datoCmsContactPageForm.fromEmail,
          data.datoCmsContactPageForm.subsidiaryEmail,
          data.datoCmsContactPageForm.subsidiaryEmailSubject,
          subsidiaryEmailContent
        )
        await Promise.all([userEmailPromise, subsidiaryEmailPromise])
        TagManager.dataLayer({
          dataLayer: {
            event: "submit_form_contact",
          },
        })
        setOnSubmitSuccess(true)
      } catch (error) {
        setOnSubmitError(true)
      }
    } else {
      setShowRecaptchaError(true)
    }
  }

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={useSlugValue(pageContext.slugs._allContactPageSlugLocales, pageContext.locale)}
      pageTemplate="contact"
    >
      <section className={styles.container}>
        <div className={styles.breadcrumb}>
          <Breadcrumb
            title={data.datoCmsContactPageForm.title}
            homeLabel={data.datoCmsGlobalConfiguration.homePageTitle}
            middlePages={data.datoCmsContactPageForm.middlePages}
          />
        </div>
        <h1 className={styles.title}>{data.datoCmsContactPageForm.title}</h1>
        <div className={styles.formContainer}>
          {onSubmitSuccess ? (
            <SuccessPage data={data.datoCmsContactPageForm} />
          ) : (
            <>
              {data.datoCmsContactPageForm.simpleText[0] && (
                <SimpleText className={styles.chapo} data={data.datoCmsContactPageForm.simpleText[0]} />
              )}
              <form role="form" onSubmit={handleSubmit(onSubmit)}>
                <FormInputs
                  formFields={data.datoCmsContactPageForm.formFields}
                  register={register}
                  errors={errors}
                  emailFieldError={data.datoCmsFormConfiguration.emailFieldError}
                  requiredFieldErrorMsg={data.datoCmsFormConfiguration.requiredFieldError}
                />
                <div className={styles.checkboxContainer}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    aria-label={data.datoCmsContactPageForm.checkboxText}
                  />
                  <p className={styles.checkboxText}>{data.datoCmsContactPageForm.checkboxText}</p>
                </div>
                <div className={styles.richContentContainer}>
                  <RichDatoCmsContent data={data.datoCmsContactPageForm.legalText} />
                </div>
                {displayRecaptcha()}
                <input
                  className={styles.submitButton}
                  type="submit"
                  name="submit"
                  value={data.datoCmsContactPageForm.buttonText}
                  onClick={() => !recaptchaValidated && setShowRecaptchaError(true)}
                />
              </form>
            </>
          )}
          <div className={onSubmitError ? styles.showErrorMessage : styles.hideErrorMessage}>
            {data.datoCmsFormConfiguration.unknownErrorMessage}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsContactPageForm.title}
      description={data.datoCmsGlobalConfiguration.metaDescription}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <HrefLangLinks
        defaultDomain={window.location.origin}
        defaultLocale={pageContext.defaultLocale}
        slugs={[pageContext.slugs._allContactPageSlugLocales]}
      />
    )}
  </>
)

export const pageQuery = graphql`
  query contactPage($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
      homePageTitle
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }
    datoCmsContactPageForm(locale: $locale) {
      ...ContactPageFormFields
    }
    datoCmsFormConfiguration(locale: $locale) {
      ...FormConfigurationFields
    }
  }
`
export const fragment = graphql`
  fragment ContactPageFormFields on DatoCmsContactPageForm {
    id
    title
    subtitle
    image {
      alt
      format
      title
      url
    }
    buttonText
    checkboxText
    legalText {
      ... on DatoCmsStructuredText {
        __typename
        structuredText {
          value
        }
      }
    }
    simpleText {
      ...SimpleTextFields
    }
    formFields {
      ...ContactPageFields
    }
    middlePages {
      ...InternalExternalLinkBlockFields
    }
    ...ReadMoreContactPageFields
    fromEmail
    subsidiaryEmail
    userEmailSubject
    userEmailContent {
      value
      blocks
      links {
        id: originalId
        fragmentType
      }
    }
    subsidiaryEmailSubject
    subsidiaryEmailContent {
      value
      blocks
      links {
        id: originalId
        fragmentType
      }
    }
    acceptedTermsEmail
    refusedTermsEmail
    successTitle
    successMessage {
      ... on DatoCmsStructuredText {
        __typename
        structuredText {
          value
        }
      }
    }
  }

  fragment FormConfigurationFields on DatoCmsFormConfiguration {
    emailFieldError
    requiredFieldError
    unknownErrorMessage
    recaptchaError
    checkboxError
    recaptchaSiteKey
    recaptchaSecretKey
  }
`

export default ContactPage
