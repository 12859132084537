import React from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, NewsroomPageQuery } from "../../../../graphql-types"
import * as styles from "./newsroom.module.scss"
import { useFormatLocale, useIsMobile, useSlugValue } from "../../hooks"
import Layout from "../../components/layout/layout"
import Banner from "../../components/banner/banner"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import MediaCards from "../../components/media-cards/media-cards"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: NewsroomPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

const Newsroom: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()
  const newsLink = useSlugValue(pageContext.slugs._allNewsPageSlugLocales, pageContext.locale)
  const pressReleasesLink = useSlugValue(pageContext.slugs._allPressReleasesPageSlugLocales, pageContext.locale)
  const publicationsLink = useSlugValue(pageContext.slugs._allPublicationsPageSlugLocales, pageContext.locale)

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={useSlugValue(pageContext.slugs._allNewsroomPageSlugLocales, pageContext.locale)}
      pageTemplate="newsroom"
    >
      <section className={styles.container}>
        <Banner title={data.datoCmsNewsroom.newsroomPageTitle} image={data.datoCmsNewsroom.newsroomPageImage} />

        <div className={styles.content}>
          {!isMobile && <Breadcrumb title="Newsroom" homeLabel={data.datoCmsGlobalConfiguration.homePageTitle} />}
          {data.datoCmsNewsroom.newsroomInTheSpotlightIsEnabled && (
            <MediaCards
              title={data.datoCmsNewsroom.newsroomInTheSpotlightTitle}
              text={data.datoCmsNewsroom.newsroomInTheSpotlightText}
              news={data.allDatoCmsNews.nodes}
              pressReleases={data.allDatoCmsPressRelease.nodes}
              publications={data.allDatoCmsPublication.nodes}
              limit={15}
            />
          )}
          {data.datoCmsNewsroom.newsroomNewsIsEnabled && (
            <MediaCards
              title={data.datoCmsNewsroom.newsroomNewsTitle}
              text={data.datoCmsNewsroom.newsroomNewsText}
              buttonLink={newsLink}
              buttonLabel={data.datoCmsNewsroom.newsroomNewsRedirectionButtonLabel}
              news={data.allDatoCmsNews.nodes}
              limit={4}
            />
          )}
          {data.datoCmsNewsroom.newsroomPressReleaseIsEnabled && (
            <MediaCards
              title={data.datoCmsNewsroom.newsroomPressReleaseTitle}
              text={data.datoCmsNewsroom.newsroomPressReleaseText}
              buttonLink={pressReleasesLink}
              buttonLabel={data.datoCmsNewsroom.newsroomPressReleaseButtonLabel}
              pressReleases={data.allDatoCmsPressRelease.nodes}
              limit={4}
            />
          )}
          {data.datoCmsNewsroom.newsroomPublicationsIsEnabled && (
            <MediaCards
              title={data.datoCmsNewsroom.newsroomPublicationsTitle}
              text={data.datoCmsNewsroom.newsroomPublicationsText}
              buttonLink={publicationsLink}
              buttonLabel={data.datoCmsNewsroom.newsroomPublicationsButtonLabel}
              publications={data.allDatoCmsPublication.nodes}
              categories={data.datoCmsPublicationCategoryList.categories.map(category => category.category)}
              limit={4}
            />
          )}
        </div>
      </section>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsNewsroom.newsroomPageTitle}
      description={data.datoCmsGlobalConfiguration.metaDescription}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <HrefLangLinks
        defaultDomain={window.location.origin}
        defaultLocale={pageContext.defaultLocale}
        slugs={[pageContext.slugs._allNewsroomPageSlugLocales]}
      />
    )}
  </>
)

export const pageQuery = graphql`
  query NewsroomPage($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsNewsroom(locale: $locale) {
      newsroomPageTitle
      newsroomPageImage {
        alt
        url
      }
      ...NewsroomInTheSpotlightFields
      ...NewsroomNewsFields
      ...NewsroomPressReleasesFields
      ...NewsroomPublicationsFields
    }

    allDatoCmsNews(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsNews {
          ...NewsMediaFields
        }
      }
    }
    allDatoCmsPressRelease(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsPressRelease {
          ...PressReleaseMediaFields
        }
      }
    }
    allDatoCmsPublication(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsPublication {
          ...PublicationMediaFields
        }
      }
    }

    datoCmsPublicationCategoryList(locale: $locale) {
      categories {
        category
      }
    }
  }
`

export default Newsroom
