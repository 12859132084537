import React, { useState } from "react"
import { graphql } from "gatsby"
import { V3PresentationTypePageFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./presentation.module.scss"
import sanitizeHtml from "sanitize-html"
import { DisplayImage } from "../../../../components/display-image/display-image"
import { useLocale } from "../../../context/locale-context"

type RenderProps = {
  data: V3PresentationTypePageFieldsFragment
  isParentPage?: boolean
}

const Presentation: React.FC<RenderProps> = ({ data, isParentPage = false }) => {
  const { locale, defaultLocale } = useLocale()

  return (
    <div className={`${styles.presentation} ${isParentPage && styles.parentPagePadding}`}>
      {data.presentation.map((block, index) => {
        const [indexSelected, setIndex] = useState(null)
        const mouseEnterListener = (idx: number) => setIndex(idx)
        const mouseLeaveListener = () => setIndex(null)
        const shouldHovered = indexSelected === index

        return (
          <div
            className={index % 2 === 0 ? styles.content : styles.contentReversed}
            key={block.id}
            style={{ "--block-color": block.v3BlockColor.hex } as React.CSSProperties}
          >
            {block.blockImage && (
              <div className={styles.imageWrapper}>
                <DisplayImage image={block.blockImage} svgStyle={styles.image} imgStyle={styles.image} />
              </div>
            )}
            <div className={styles.information}>
              {block.blockTitle && <h3 className={styles.title}>{block.blockTitle}</h3>}
              {block.chapo && (
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(block.chapo) }} className={styles.chapo} />
              )}
              <a
                className={styles.link}
                href={
                  locale === defaultLocale ? `/${block.linkToPage[0].slug}` : `/${locale}/${block.linkToPage[0].slug}`
                }
                target="_self"
                style={{ color: shouldHovered ? `${block.v3BlockColor.hex}` : `#ffffff` }}
              >
                <div
                  className={styles.linkContent}
                  onMouseEnter={() => mouseEnterListener(index)}
                  onMouseLeave={mouseLeaveListener}
                  tabIndex={0}
                  style={{
                    backgroundColor: shouldHovered ? `#ffffff` : `${block.v3BlockColor.hex}`,
                    color: shouldHovered ? `${block.v3BlockColor.hex}` : `#ffffff`,
                    border: shouldHovered && `0.5px solid ${block.v3BlockColor.hex}`,
                  }}
                >
                  <p className={styles.linkContentText}>{block.actionButtonText}</p>
                </div>
              </a>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const fragment = graphql`
  fragment V3PresentationTypePageFields on DatoCmsPresentation {
    presentation {
      ...V3PresentationBlockFields
    }
  }

  fragment V3PresentationParentPageFields on DatoCmsParentPage {
    presentation {
      ...V3PresentationBlockFields
    }
  }

  fragment V3PresentationBlockFields on DatoCmsPresentationBlock {
    id
    v3BlockColor {
      hex
    }
    actionButtonText
    blockTitle
    blockImage {
      alt
      format
      title
      url
    }
    chapo
    linkToPage {
      ... on DatoCmsParentPage {
        id
        title
        subtitle
        chapo
        slug
        image {
          alt
          format
          title
          url
        }
      }
      ... on DatoCmsPage {
        id
        title
        subtitle
        chapo
        slug
        image {
          alt
          format
          title
          url
        }
      }
      ... on DatoCmsPressRelease {
        id
        title
        subtitle
        chapo
        slug
        image {
          alt
          format
          title
          url
        }
      }
      ... on DatoCmsNews {
        id
        title
        chapo
        slug
        image {
          alt
          format
          title
          url
        }
      }
    }
  }
`

export default Presentation
