import React, { useRef } from "react"
import { graphql } from "gatsby"
import { PartnersContentFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./partners.module.scss"
import { useIsMobile } from "../../../hooks"
import { DisplayImage } from "../../../../components/display-image/display-image"
import ScrollArrow from "../../tools/scroll-arrow/scroll-arrow"

type RenderProps = {
  data: PartnersContentFieldsFragment
}

const Partners: React.FC<RenderProps> = ({ data }) => {
  const isMobile = useIsMobile()
  const cards = useRef(null)
  const scrollToCard = (scrollDirection: number) => {
    const itemWidth = cards.current.children[0].clientWidth + 4
    cards.current.scrollBy({
      left: itemWidth * scrollDirection,
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title} style={{ "--color-block": data.color.hex } as React.CSSProperties}>
        {data.title}
      </h2>
      <div className={styles.alignRight}>
        <div className={styles.imageContainer} ref={cards}>
          {data.partners.map((partner, index) => (
            <a key={index} href={partner.url} target="_blank" className={styles.imageContent}>
              <DisplayImage image={partner.logo} />
            </a>
          ))}
        </div>
        {!isMobile && data.partners.length > 4 && (
          <div className={styles.arrowsContainer}>
            <ScrollArrow direction="left" onClick={() => scrollToCard(-1)} />
            <ScrollArrow direction="right" onClick={() => scrollToCard(1)} />
          </div>
        )}
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment PartnersContentFields on DatoCmsPartnersContent {
    __typename
    title
    color {
      hex
    }
    partners {
      logo {
        ...PartnerImageFields
      }
      url
    }
  }

  fragment PartnerImageFields on DatoCmsFileField {
    alt
    format
    url
  }
`

export default Partners
