import * as config from "../../config"

export const sendEmail = (from: string, to: string, subject: string, content: string): Promise<Response> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": config.notifications_service_token,
  }

  return fetch(config.notifications_service_endpoint, {
    method: "POST",
    headers,
    body: JSON.stringify({
      notificationType: "Email",
      notificationContent: {
        from,
        to,
        emailType: "NoTemplateWithLogoAndAttachments",
        content: {
          subject,
          htmlMessage: content,
        },
      },
    }),
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response
  })
}

// We don't want to know the type because we're not supposed to do anything with it.
// If that assumption changes, see the OAS of the service (on Keolis.Notifications repository on Azure devops)
type SavedFile = unknown
