// extracted by mini-css-extract-plugin
export var allCardLink = "v3-biographic-card-vertical-module--allCardLink--f8a8b";
export var bioContainer = "v3-biographic-card-vertical-module--bioContainer--516c7";
export var card = "v3-biographic-card-vertical-module--card--70636";
export var cardsContainer = "v3-biographic-card-vertical-module--cardsContainer--b74bf";
export var linkLinkedin = "v3-biographic-card-vertical-module--linkLinkedin--20882";
export var linkTwitter = "v3-biographic-card-vertical-module--linkTwitter--001eb";
export var memberPhoto = "v3-biographic-card-vertical-module--memberPhoto--56353";
export var name = "v3-biographic-card-vertical-module--name--97bd9";
export var navigation = "v3-biographic-card-vertical-module--navigation--7b46c";
export var socialNetworkContainer = "v3-biographic-card-vertical-module--socialNetworkContainer--db6c9";